<template>
  <div v-if="liveChatOpen">
    <div id="mainChat" :class="{ minimized: chatOpen == false }">
      <v-system-bar color="#1C1C44" dark window>
        <v-icon small>$chatIcon</v-icon>
        <span>Live Chat</span>
        <v-spacer></v-spacer>
        <v-icon v-if="!chatOpen" @click.prevent="chatOpen = !chatOpen">
          mdi-checkbox-blank-outline
        </v-icon>
        <v-icon v-else @click.prevent="chatOpen = !chatOpen">mdi-minus</v-icon>
        <v-icon @click="liveChatOpen = false">mdi-close</v-icon>
      </v-system-bar>
      <div id="conversations">
        <div
          v-for="(c, ck) in allChats"
          :key="'contacts-' + ck"
          @click="openChat(c)"
          class="contact"
        >
          <div class="contact_name">{{ getFromTo(c.ToUser, c.FromUser) }}</div>
          <div
            class="contact_msg d-flex justify-space-between"
            v-if="c.lastMessage"
          >
            <div v-if="c.lastMessage">
              {{ c.lastMessage.msg }}
            </div>
            <div class="text-capitalize" style="font-size: 10px">
              <template v-if="c.lastMessage.dayOfMessage != 'HOJE'">
                {{ c.lastMessage.dayOfMessage }}
              </template>
              <template v-else>
                {{ c.lastMessage.criado_em | toOnlyTime }}
              </template>
            </div>
          </div>
          <div v-else>Novo contato</div>
        </div>
      </div>
    </div>

    <div
      v-for="(c, ck) in openChats"
      :key="'openchat-' + ck"
      :style="{ left: 80 + (ck + 1) * 290 + 'px' }"
      class="openChat"
      :class="{ minimized: c.isMinimized == false }"
    >
      <v-system-bar color="#1C1C44" dark window>
        <v-icon>mdi-message</v-icon>
        <span class="text-truncate">{{ getFromTo(c.ToUser, c.FromUser) }}</span>
        <v-spacer></v-spacer>
        <v-icon v-if="!c.isMinimized" @click.prevent="c.isMinimized = true"
          >mdi-checkbox-blank-outline</v-icon
        >
        <v-icon v-else @click.prevent="c.isMinimized = false">mdi-minus</v-icon>
        <v-icon @click="c.isOpen = false">mdi-close</v-icon>
      </v-system-bar>
      <div class="chat_conversation" id="chat_container">
        <div v-if="c.messagesLoaded === false" style="padding: 5px">
          Carregando mensagens
        </div>

        <div
          v-if="c.messagesLoaded && c.messages.length === 0"
          style="padding: 5px"
        >
          Não há mensagens nesta conversa.
        </div>

        <template v-for="(m, mk) in getMessages(c.messages)">
          <div
            :key="'day-' + mk"
            class="chat-msg-elevation mx-auto p-1 mt-3 text-center text-uppercase"
            style="
              border-radius: 7.5px;
              background-color: rgba(225, 245, 254, 1);
              width: 110px;
              font-size: 10px;
              position: sticky;
              top: 12px;
              z-index: 1;
            "
            v-if="m.firstMessageOfDay"
          >
            {{ m.dayOfMessage }}
          </div>
          <div
            :key="'message-' + mk"
            :class="{
              'mt-3': m.firstMessage || m.firstMessageOfDay,
              'mr-3': m.isMyMsg,
              'ml-auto': m.isMyMsg,
              'mr-auto': !m.isMyMsg,
              'ml-3': !m.isMyMsg,
              'chat-msg-mine': m.isMyMsg,
              'chat-msg-other': !m.isMyMsg,
            }"
            class="chat-msg-elevation"
            style="
              padding: 10px;
              margin-bottom: 2px;
              border-radius: 7.5px;
              max-width: 80%;
              position: relative;
            "
          >
            <div
              v-if="m.firstMessage || m.firstMessageOfDay"
              style="font-size: 13px; font-weight: bold"
            >
              {{
                m.user == c.ToUser.id
                  ? c.ToUser.nome
                  : c.FromUser.nome | chatUsername
              }}
            </div>
            <div style="font-size: 12px">{{ m.msg }}</div>
            <div
              style="
                font-size: 10px;
                right: 4px;
                bottom: 0px;
                position: absolute;
                margin-left: auto;
              "
            >
              {{ m.criado_em | toOnlyTime }}
            </div>
          </div>
        </template>
      </div>
      <div class>
        <v-text-field
          @keydown.enter.prevent="sendMessage(c)"
          v-model="c.newMessage"
          hide-details
          dense
          outlined
        ></v-text-field>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import * as moment from "moment";

/**
 * Message returned by API
 * @typedef {Object} MessageResponse
 * @property {string} action
 * @property {Object} data
 * @property {string} data.msg
 * @property {number} data.chatId
 * @property {number} data.user
 * @property {string} data.modificado_em
 * @property {string} data.criado_em
 * @property {number} data.id
 */

export default {
  props: ["token", "myId"],
  created: function () {
    this.createWS();

    this.resource.get().then((v) => {
      for (var c in v) {
        v[c].isOpen = false;
        v[c].isMinimized = true;
        v[c].messagesLoaded = false;
        v[c].messages = [];
        v[c].newMessage = "";
        v[c].lastMessage = this.updateDayOfMsg(v[c].lastMessage);
      }

      this.chats = [].concat(v);

      this.resourceUsers.get().then((users) => {
        _.each(users, (user) => {
          var hasChat = _.some(
            this.chats,
            (chat) => chat.user1 == user.id || chat.user2 == user.id
          );

          if (!hasChat) {
            this.chats.push({
              isOpen: false,
              isMinimized: true,
              messagesLoaded: false,
              messages: [],
              newMessage: "",
              user1: this.myId,
              user2: user.id,
              ToUser: user,
              FromUser: { id: this.myId },
              id: null,
            });
          }
        });
      });
    });

    this.$root.$on("live-chat:toggle", (open) => {
      if (typeof open != "undefined") {
        this.liveChatOpen = open;
      } else {
        this.liveChatOpen = !this.liveChatOpen;
      }

      if (this.liveChatOpen) {
        this.chatOpen = true;
      }
    });

    this.$root.$on("chat:open", (args) => {
      var check = this.findChat(args.id);
      if (check) {
        this.openChat(check);
      } else {
        var tmp = {};
        tmp.isOpen = true;
        tmp.isMinimized = true;
        tmp.messagesLoaded = false;
        tmp.messages = [];
        tmp.FromUser = {
          id: this.$store.getters.jwtData.data.usuarioId,
          nome: this.$store.getters.jwtData.data.nome,
        };
        tmp.ToUser = {
          id: args.id,
          nome: args.nome,
        };
        tmp.newMessage = "";

        this.openChat(tmp);

        this.chats.push(tmp);
      }
    });
  },
  filters: {
    chatUsername: function (name) {
      return name && name.includes("@") ? name.split("@")[0] : name;
    },
  },
  methods: {
    findChat: function (id) {
      return this.chats.find((chat) => chat.id == id);
    },
    getMessages: function (messages) {
      var currentUserId = null;
      var dayOfMessage = null;

      moment.locale("pt-br");
      return _.orderBy(messages, "id", "asc").map((msg) => {
        msg.criado_em = msg.criado_em || moment().format();
        msg.dayOfMessage = moment(msg.criado_em)
          .startOf("day")
          .calendar(moment().startOf("day"), this.format);
        msg.firstMessageOfDay = dayOfMessage != msg.dayOfMessage;
        msg.firstMessage = currentUserId != msg.user;
        msg.isMyMsg = this.myId == msg.user;
        dayOfMessage = msg.dayOfMessage;
        currentUserId = msg.user;
        return msg;
      });
    },
    updateDayOfMsg: function (msg) {
      moment.locale("pt-br");
      msg.criado_em = msg.criado_em || moment().format();
      msg.dayOfMessage = moment(msg.criado_em)
        .startOf("day")
        .calendar(moment().startOf("day"), this.format);
      msg.isMyMsg = this.myId == msg.user;
      return msg;
    },
    getFromTo: function (a, b) {
      if (a.id == parseInt(this.myId)) {
        return b.nome;
      } else {
        return a.nome;
      }
    },
    sendMessage: function (c) {
      if (c.newMessage !== "") {
        this.resource
          .silentSave(
            { user1: this.myId, user2: c.user2, msg: c.newMessage },
            c.id
          )
          .then((v) => {
            this.websocket.send(JSON.stringify(v));
            c.messages.push(v.data);
            c.lastMessage = v;

            this.scrollToBottomChat();
          });

        this.sounds.send.play();

        window.setTimeout(() => {
          c.newMessage = "";
        }, 100);
      }
    },
    openChat: function (c) {
      if (!this.liveChatOpen) {
        this.liveChatOpen = true;
      }

      c.isOpen = true;
      c.isMinimized = true;
      this.loadChatMessage(c);
      this.scrollToBottomChat();
    },
    loadChatMessage: function (c) {
      if (c.messagesLoaded === false) {
        this.resource.get(c.id).then((v) => {
          c.messages = v;
          c.messagesLoaded = true;
          this.scrollToBottomChat();
          c.messages = this.getMessages(c.messages);
        });
      }
    },
    createWS: function () {
      this.websocket = new WebSocket(
        `${process.env.VUE_APP_WS_URL}/?authToken=${this.token}`
      );

      this.websocket.onmessage = (e) => {
        /** @type {MessageResponse} */
        let cmd = JSON.parse(e.data);

        if (cmd.action === "newmessage") {
          var chat = this.findChat(cmd.data.chatId);

          if (chat) {
            const { chatId, msg } = cmd.data;

            let localMessageIndex = chat.messages.findIndex(
              (message) => message.chatId === chatId && message.msg === msg
            );

            if (localMessageIndex > 0) {
              chat.messages.splice(localMessageIndex, 1, cmd.data);
            } else {
              chat.messages.push(cmd.data);
            }

            chat.lastMessage = cmd.data;

            if (chat.isOpen == false || chat.isMinimized == false) {
              this.sounds.receive.play();
            } else {
              this.sounds.send.play();
            }

            this.openChat(chat);
          } else {
            chat.id = null;
            chat.isOpen = true;
            chat.isMinimized = true;
            chat.messagesLoaded = false;
            chat.messages = [];
            chat.FromUser = {
              id: cmd.data.user,
              nome: cmd.data.nome,
            };
            chat.ToUser = {
              id: this.$store.getters.jwtData.data.usuarioId,
              nome: this.$store.getters.jwtData.data.nome,
            };
            chat.newMessage = "";

            this.openChat(chat);
            this.chats.push(chat);

            this.sounds.send.play();
          }

          this.scrollToBottomChat();
        }
      };

      this.websocket.onclose = () => {
        setTimeout(this.createWS, 200);
      };

      this.websocket.onerror = (e) => {
        console.error(
          "Socket encontrou um erro: ",
          e.message,
          "Reabrindo socket..."
        );
        this.websocket.close();
      };
    },
    scrollToBottomChat: function () {
      setTimeout(() => {
        let chat_container = this.$el
          .querySelectorAll("#chat_container")
          .item(0);
        if (chat_container) {
          chat_container.scrollTop = chat_container.scrollHeight;
        }
      }, 100);
    },
  },
  computed: {
    resource: function () {
      return this.apiResource("/v1/chat");
    },
    resourceUsers: function () {
      return this.apiResource("/v1/chat/users");
    },
    allChats: function () {
      return _.orderBy(
        this.chats,
        function (a) {
          return a.lastMessage ? a.lastMessage.id : 0;
        },
        "desc"
      );
    },
    openChats: function () {
      return _.filter(this.chats, { isOpen: true });
    },
  },
  data: function () {
    return {
      liveChatOpen: false,
      format: {
        sameDay: "[HOJE]",
        nextDay: "[AMANHÃ]",
        nextWeek: "[próxima] dddd",
        lastDay: "[ONTEM]",
        lastWeek: "dddd",
        sameElse: "DD/MM/YYYY",
      },
      sounds: {
        send: new Audio("/sounds/message_send.mp3"),
        receive: new Audio("/sounds/message_received.mp3"),
      },
      websocket: null,
      chatOpen: false,
      chats: [],
    };
  },
};
</script>

<style scoped>
#mainChat {
  z-index: 200;
  position: fixed;
  left: 80px;
  bottom: 16px;
  width: 280px;
  height: 400px;
  background: white;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.2);
}

.openChat {
  z-index: 200;
  position: fixed;
  left: 80px;
  bottom: 16px;
  width: 280px;
  height: 400px;
  background: white;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.2);
}

#head,
.chat_head {
  background: #1c1c44;
  color: white;
  padding: 15px;
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

#conversations {
  height: calc(100% - 32px);
  overflow-y: auto;
}

.chat_name {
  float: left;
}

.chat_close {
  float: right;
  cursor: pointer;
}

.chat_conversation {
  height: calc(100% - 72px);
  overflow-y: scroll;
}

.chat_input input {
  border: none;
  border-top: #becde4 1px solid;
  width: 100%;
  height: 35px;
}

.contact {
  padding: 15px;

  border-bottom: #becde4 1px solid;
}

.contact:hover {
  background: rgba(0, 0, 0, 0.05);
}

.contact_name {
  font-size: 13px;
  color: black;
  font-weight: 500;
}

.contact_msg {
  font-size: 11px;
  color: black;
}

.minimized {
  height: 32px !important;
  overflow: hidden;
}
</style>
